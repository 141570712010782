import React, { useEffect, useState, useRef } from "react";

import { regulatoryContext } from "../api/data";

import EuEtsChart from "./charts/EuEtsChart";
import EmissionIntensity from "./charts/EmissionIntensity";
import SelectBox from "./SelectBox";

const EuTabContent = ({
  euPenalty,
  setEuPenalty,
  euCostEur,
  setEuCostEur,
  euCostCagr,
  setEuCostCagr,
  baseYear,
  setBaseYear,
  years,
  showBaseYearError,
  setShowbaseYearError,
  euPaneltyError,
  setEuPaneltyError,
  euCostError,
  setEuCostError,
  showGrowthError,
  setShowGrowthError,
}) => {
  const [chartData, setChartData] = useState([]);

  const costAllowanceRef = useRef(null);
  const penaltyInputRef = useRef(null);
  const growthRef = useRef(null);
  const [isTouchedGrowth, setIsTouchedGrowth] = useState(false);
  const [isTouchedCost, setIsTouchedCost] = useState(false);
  const [isTouchedPenalty, setIsTouchedPenalty] = useState(false);
  const [viewEucostCagrValue, setViewEucostCagrValue] = useState(null);

  const getRegulatoryContext = () => {
    regulatoryContext()
      .then((res) => {
        setChartData(res.data);
      })
      .catch((err) => console.log("err", err));
  };

  const handleChangeEuPenalty = (event) => {
    let value = event.target.value.trim();
    value = removeDecimalPart(value);
    handleEuPenalty(value);
  };

  const handleChangeEuCostEur = (event,) => {
    let value = event.target.value.trim();
    value = removeDecimalPart(value);
    handleEuCostEur(value);
  };

  const removeDecimalPart = (value) => {
    value = value.replace(/[-+]+$/, '');
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      if (decimalPart.length > 1) {
        value = `${integerPart}.${decimalPart.slice(0, 1)}`;
      }
    }
    return value;
  }

  const handleEuPenalty = (value) => {
    const parsedValue = parseFloat(value);
    if (
      isNaN(parsedValue) ||
      parsedValue < 0 ||
      parsedValue > 5000 ||
      value.startsWith("-")
    ) {
      value = value.slice(0, 4);
      setEuPaneltyError("Please enter penalty between 0 to 5000");
      setEuPenalty(value);
    } else {
      setEuPenalty(value);
      setEuPaneltyError("");
    }
  };

  const handleEuCostEur = (value) => {
    const parsedValue = parseFloat(value);
    if (
      isNaN(parsedValue) ||
      parsedValue < 0 ||
      parsedValue > 500 ||
      value.startsWith("-")
    ) {
      value = value.slice(0, 3);
      setEuCostError("Cost of allowance must be between 0 to 500");
      setEuCostEur(value);
    } else {
      setEuCostEur(value);
      setEuCostError("");
    }
  }

  handleEuCostEur(euCostEur)
  handleEuPenalty(euPenalty)

  const handleChangeEuCostCagr = (event) => {
    let value = event.target.value.trim();
    // Remove non-numeric characters except for a period.
    value = value.replace(/[^0-9.]/g, '');
   
    // If value is empty, reset the state and return.
    if (!value) {
      setEuCostCagr("");
      setViewEucostCagrValue("");
      setShowGrowthError("Growth p/a must be 0 to 10%");
      return;
    }

    // Parse the value and ensure it's numeric.
    let parsedValue = parseFloat(value);

    if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 10) {
      // Format value to one decimal place.
      let formattedValue = parsedValue.toFixed(1);
      setEuCostCagr((parsedValue / 100).toFixed(4)); // Keep division value to four decimals.
      setViewEucostCagrValue(formattedValue); // Display the formatted value.
      setShowGrowthError("");
    } else {
        // Reset state if validation fails.
        setEuCostCagr("");
        setViewEucostCagrValue("");
        setShowGrowthError("Growth p/a must be 0 to 10%");
    }
  };

  const handlFocusCost = () => {
    setIsTouchedCost(true);
  }

  const handleFocusPenalty = () => {
    setIsTouchedPenalty(true);
  }

  const handleFocusGrowth = () => {
    setIsTouchedGrowth(true);
  }

  const handleClickOutside = (event) => {
    if (penaltyInputRef.current && !penaltyInputRef.current.contains(event.target)) {
      setIsTouchedPenalty(false);
    }
    if (costAllowanceRef.current && !costAllowanceRef.current.contains(event.target)) {
      setIsTouchedCost(false);
    }
    if (growthRef.current && !growthRef.current.contains(event.target)) {
      setIsTouchedGrowth(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getRegulatoryContext();
  }, []);
  return (
    <div className="eu-content-container">
      <div className="eu-ets-container p-0 pt-4 gap-4 graph-containter border-1 rounded-3 bg-white overflow-hidden d-flex flex-column justify-content-between">
      <div className="px-4">
        <div className="title">
          <p className="mb-0 fill-width text-dark ">EU-ETS</p>
        </div>
        <div className="input-container">
          <div className="first-container d-flex align-items-center gap-4 justify-content-between flex-wrap">
            <div className="cost-of-compliance" ref={costAllowanceRef}>
              <div className="d-flex align-items-center justify-content-start">
              <p className="input-label fs-14 me-2 mb-0">Cost of Allowance</p>
              <div className="mail-input pos-relative parameter common-input-v2">
                <span>EUR/mtC02-eq</span>
                <input
                  type="number"
                  name="eufmNonComliance"
                  placeholder="Cost"
                  value={euCostEur || ""}
                  onChange={(e) => handleChangeEuCostEur(e)}
                  onFocus={handlFocusCost}
                  onKeyDown={(e) => {
                      if (e.key === '-' || e.key === '+') {
                          e.preventDefault();
                      }
                  }}
                  className="common-input-v2"
                />
              </div>
              </div>
                {isTouchedCost && euCostError && <p className="error mb-0 h-auto">{euCostError}</p>}
            </div>
            <div className="base-year">
              <div className="d-flex align-items-center justify-content-start">
                <p className="input-label fs-14 me-2 mb-0">Base year</p>
                <SelectBox
                  show={true}
                  selectedItem={baseYear}
                  listItems={years}
                  setSelectedItem={(item) => setBaseYear(item)}
                  disabledOptions={[baseYear]}
                  showError={showBaseYearError}
                  setShowError={setShowbaseYearError}
                  className="small-select-box"
                  selectBoxContainer="w-auto"
                />
              </div>
              <p className="error mb-0 h-auto"></p>
            </div>
            <div className="growth-pa" ref={growthRef}>
              <div className="d-flex align-items-center justify-content-start">
              <p className="input-label mb-0 fs-14 me-2">Growth p/a</p>
              <div className="mail-input pos-relative parameter common-input-v2">
                <span className="inside-text">%</span>
                <input
                  type="number"
                  name="eufmNonComliance"
                  placeholder="Enter value"
                  value={(viewEucostCagrValue) || ''}
                  onChange={(e) => {
                    handleChangeEuCostCagr(e)}
                  }
                  onFocus={handleFocusGrowth}
                  onKeyDown={(e) => {
                      if (e.key === '-' || e.key === '+') {
                          e.preventDefault();
                      }
                  }}
                  className="common-input-v2"
                />
              </div>
              </div>
              {isTouchedGrowth && showGrowthError && <p className="error mb-0 h-auto">
                {showGrowthError}
              </p>}
            </div>
          </div>
        </div>
        </div>
        <div className="cii-zfactor-chart mt-2">
          <div className="chart-bg">
            <EuEtsChart chartData={chartData} />
          </div>
        </div>
      </div>
      <div className="eu-fm-container p-0 pt-3 gap-4 graph-containter border-1 rounded-3 bg-white overflow-hidden d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between px-4 align-items-center h-60">
          <div className="title mb-2">
            <p className="mb-0 text-dark">EU-FM</p>
          </div>
          <div className="penlty-input mb-2" ref={penaltyInputRef}>
            <div className="d-flex align-items-center justify-content-end">
              <p className="input-label fs-14 me-2 mb-0">Penalty non-compliance</p>
              <div className="mail-input pos-relative parameter common-input-v2">
                <span >EUR/GJ</span>
                <input
                  type="number"
                  name="eufmNonComliance"
                  placeholder="Enter Penalty"
                  value={euPenalty || ""}
                  onChange={(e) => handleChangeEuPenalty(e)}
                  onFocus={handleFocusPenalty}
                  className="common-input-v2"
                  onKeyDown={(e) => {
                        if (e.key === '-' || e.key === '+') {
                            e.preventDefault();
                        }
                    }}
                />
              </div>
            </div>
            {isTouchedPenalty && euPaneltyError && <p className="error text-end mb-0">{euPaneltyError}</p>}
          </div>
        </div>
        <div className="cii-zfactor-chart mt-2">
          <div className="chart-bg emission-intencsity">
            <EmissionIntensity chartData={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EuTabContent;
